import { LoadingSkeleton, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";

import { getMainEventDescription, getSecondaryEventDescription } from "./utils";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { BankruptcyCommunication } from "src/types";

export function EventDescription() {
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading, data: communication } = useCommunicationWithTrackingQuery({
    brokerId,
    communicationId,
  });

  if (isLoading || !communication) {
    return (
      <Box sx={{ mt: 8, px: 4 }}>
        <LoadingSkeleton sx={{ mb: 1 }} />
        <LoadingSkeleton sx={{ mb: 1 }} />
        <LoadingSkeleton />
      </Box>
    );
  }

  return (
    <>
      <Text
        variant="body2"
        sx={{ mt: 8, px: 4 }}
        data-testid="landing-page-main"
      >
        {getMainEventDescription(communication as BankruptcyCommunication)}
      </Text>
      <Text
        variant="body2"
        sx={{ mt: 8, px: 4 }}
        data-testid="landing-page-secondary"
      >
        {getSecondaryEventDescription(communication as BankruptcyCommunication)}
      </Text>
    </>
  );
}
