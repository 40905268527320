import { BankruptcyCommunication } from "src/types/Communication";

export function getEventHeader(communication: BankruptcyCommunication) {
  if (!communication) {
    return "";
  }

  if (communication.bankruptcyEvent.chapter == "generic") {
    return "Bankruptcy";
  }

  if (communication.bankruptcyEvent.chapter == "chapter_7") {
    return "Chapter 7 Bankruptcy";
  }

  if (communication.bankruptcyEvent.chapter == "chapter_11") {
    return "Chapter 11 Bankruptcy";
  }

  return "";
}
