import { BankruptcyCommunication } from "src/types/Communication";
import { formatDate } from "src/utils/formatDate";

export function getMainEventDescription(
  communication: BankruptcyCommunication
) {
  const { issuerName } = communication.security;
  const { filingDate } = communication.bankruptcyEvent;
  const formattedFilingDate = formatDate({
    date: filingDate,
    timezone: undefined,
  });

  if (communication.bankruptcyEvent.chapter == "generic") {
    return `${issuerName} has filed for bankruptcy on ${formattedFilingDate}.`;
  }

  if (communication.bankruptcyEvent.chapter == "chapter_7") {
    return `${issuerName} has filed for Chapter 7 bankruptcy on ${formattedFilingDate}.`;
  }

  if (communication.bankruptcyEvent.chapter == "chapter_11") {
    return `${issuerName} has filed for Chapter 11 bankruptcy on ${formattedFilingDate}.`;
  }

  return "";
}

export function getSecondaryEventDescription(
  communication: BankruptcyCommunication
) {
  if (communication.bankruptcyEvent.chapter == "chapter_7") {
    return "The bankruptcy documents contain details regarding the bankruptcy.";
  }

  if (communication.bankruptcyEvent.chapter == "chapter_11") {
    return "The bankruptcy documents contain details regarding the bankruptcy.";
  }

  return "";
}
